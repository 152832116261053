import { BackdropFull, Bold } from 'src/shared/style/style';
import { PopupBtn, PopupIcon, PopupText, PopupWrapper, StaticModalContainer } from './style';

interface IProps {
  hide: () => void;
}

export const AdultPopUp = ({ hide }: IProps) => {
  return (
    <>
      <BackdropFull className={'active'}/>
      <StaticModalContainer>
        <PopupWrapper>
          <PopupIcon src="https://fonts.gstatic.com/s/e/notoemoji/latest/1fae3/512.gif" alt="🫣" width="64" height="64" />
          <PopupText>
            <Bold>Fupp is adults only website!</Bold>
            <p>The content available on Fupp
              <br/>
              may contain pornographic materials.</p>
          </PopupText>
          <PopupBtn onClick={hide} id='age_restricted' class="age_restricted" aria-label='I am 18 or older'>
            I'm 18 or older - Enter Fupp
          </PopupBtn>
        </PopupWrapper>
      </StaticModalContainer>
    </>
  );
};
