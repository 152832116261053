import { useLocation } from '@remix-run/react';
import { clsx } from 'clsx';

import { BottomNavIcon, BottomNavigation, BottomNavLink, LinkExtended } from './style';

interface IProps {
  popup: JSX.Element;
  showPopup: () => void;
}

export const BottomNav = ({ popup, showPopup }: IProps) => {
  const location = useLocation();

  return (
    <BottomNavigation>
      <LinkExtended to={'hot'} className={clsx(location.key === 'hot' && 'active')} aria-label='go to hot page'>
        <BottomNavIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><path fill="#fff" d="m10.673.85 1.68 1.007c2.375 1.425 3.863 3.453 4.64 5.526.297-.668.648-1.314.975-1.967l1.308 1.308C22.212 9.66 25 14.06 25 18.334c0 5.73-3.533 9.363-9.186 9.991l-1.853.206.404-1.82c.317-1.424.373-2.355.308-3.007-.081-.82-.471-1.464-.955-2.104-.465-.614-.924-1.229-1.292-1.908-1.514 1.197-2.1 2.277-2.288 3.166-.24 1.14.1 2.303.721 3.546l1.096 2.192-2.436-.27c-3.434-.382-6.818-2.676-8.15-6.025-1.395-3.502-.41-7.741 4.091-11.642C8.447 8.07 10.03 4.715 10.673.85Zm1.776 4.4c-1.016 2.853-2.966 5.451-5.242 7.424-3.882 3.364-4.23 6.459-3.361 8.64.647 1.627 2.043 2.965 3.698 3.723a6.625 6.625 0 0 1-.015-2.728c.434-2.061 1.916-3.993 4.764-5.773l1.436-.897.536 1.606c.47 1.408 1.443 2.404 2.204 3.64.837 1.36.997 2.92.828 4.478 3.491-.875 5.036-3.524 5.036-7.03 0-2.962-1.787-5.869-3.56-8.14-.73 1.636-2.271 2.213-3.773 2.965V11c0-1.825-.742-4.05-2.55-5.75Z"/></svg>
        </BottomNavIcon>
      </LinkExtended>
      <BottomNavLink onClick={showPopup}>
        <BottomNavIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><path fill="#fff" d="M12 3.333a8.667 8.667 0 1 0 0 17.334 8.667 8.667 0 0 0 0-17.334ZM.667 12C.667 5.74 5.74.667 12 .667 18.26.667 23.333 5.74 23.333 12c0 2.65-.909 5.086-2.432 7.016l4.87 4.87a1.333 1.333 0 0 1-1.885 1.885l-4.87-4.87A11.284 11.284 0 0 1 12 23.333C5.74 23.333.667 18.26.667 12Zm10-4.667C10.667 6.597 11.264 6 12 6a6 6 0 0 1 6 6 1.333 1.333 0 0 1-2.667 0A3.333 3.333 0 0 0 12 8.667a1.333 1.333 0 0 1-1.333-1.334Z"/></svg>
        </BottomNavIcon>
      </BottomNavLink>
      <BottomNavLink onClick={showPopup}>
        <BottomNavIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><path fill="#fff" d="M12.56 1.824a1.667 1.667 0 0 1 2.88 0l3.727 6.398 7.237 1.567a1.667 1.667 0 0 1 .89 2.74l-4.934 5.52.746 7.368a1.667 1.667 0 0 1-2.33 1.693L14 24.124 7.224 27.11a1.667 1.667 0 0 1-2.33-1.693l.746-7.367-4.934-5.522a1.667 1.667 0 0 1 .89-2.739l7.237-1.567 3.727-6.398ZM14 4.649l-3.084 5.296c-.236.404-.63.69-1.088.79l-5.99 1.297 4.084 4.57c.312.349.462.813.415 1.278l-.617 6.097 5.608-2.471a1.666 1.666 0 0 1 1.344 0l5.608 2.471-.617-6.097c-.047-.465.103-.93.415-1.278l4.083-4.57-5.99-1.297a1.667 1.667 0 0 1-1.087-.79L14 4.649Z"/></svg>
        </BottomNavIcon>
      </BottomNavLink>
      <BottomNavLink onClick={showPopup}>
        <BottomNavIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><path fill="#fff" d="M12 15.333c3.194 0 6.1.925 8.237 2.229 1.068.652 1.98 1.42 2.638 2.253.648.82 1.125 1.802 1.125 2.852 0 1.126-.548 2.014-1.337 2.647-.747.6-1.732.997-2.78 1.275-2.103.557-4.911.744-7.883.744-2.972 0-5.78-.187-7.884-.744-1.047-.277-2.032-.674-2.78-1.274C.549 24.68 0 23.793 0 22.666c0-1.05.477-2.031 1.125-2.852.659-.833 1.57-1.601 2.638-2.253C5.9 16.258 8.806 15.333 12 15.333ZM12 18c-2.697 0-5.124.786-6.848 1.838-.862.527-1.513 1.097-1.934 1.63-.432.547-.551.96-.551 1.199 0 .162.048.335.34.568.331.267.909.542 1.792.776 1.757.465 4.282.656 7.201.656 2.92 0 5.444-.19 7.201-.656.883-.234 1.46-.51 1.793-.776.29-.233.34-.406.34-.568 0-.24-.12-.652-.552-1.199-.42-.533-1.072-1.103-1.934-1.63C17.124 18.786 14.698 18 12 18ZM12 .667A6.667 6.667 0 1 1 12 14 6.667 6.667 0 0 1 12 .667Zm0 2.666a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"/></svg>
        </BottomNavIcon>
      </BottomNavLink>
      {popup}
    </BottomNavigation>
  );
};
