import { createDomain } from 'effector';

import { attachLogger } from 'src/shared/lib/log';

const domain = createDomain('side-menu');

attachLogger(domain);

export const showSideBar = domain.createEvent('showSideBar');
export const hideSideBar = domain.createEvent('hideSideBar');

export const $isSideBarVisible = domain.createStore(false, { name: 'isSideBarVisible' });

$isSideBarVisible.on(showSideBar, () => true);
$isSideBarVisible.on(hideSideBar, () => false);
