import styled from '@emotion/styled';

export const TopNavigation = styled.nav`
  width: 100%;
  max-width: 700px;
  z-index: 2;
  cursor: default;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  top: env(safe-area-inset-top);
  padding: 10px 15px;
  box-sizing: border-box;
  
  @media (display-mode: fullscreen) {
    padding: 0 15px;
  }
`;

export const Button = styled.div`
  cursor: pointer;
`;
