import { useUnit } from 'effector-react';
import { useLocation } from '@remix-run/react';
import { clsx } from 'clsx';

import { Backdrop, LinkExt } from 'src/shared/style/style';

import {
  SideBar,
  SideBarBack,
  SideBarBottom,
  SideBarLink,
  SideBarPoint,
  SideBarPointExt,
  SideBarPointGray,
  SideBarTop,
  SideMenuWrapper,
} from './style';
import { $isSideBarVisible } from '../model';

interface IProps {
  hideSideMenu: () => void;
}

export const SideMenu = ({ hideSideMenu }: IProps) => {
  const isVisible = useUnit($isSideBarVisible);
  const location = useLocation();

  return (
    <>
      <Backdrop onClick={hideSideMenu} className={isVisible ? 'active' : ''} />
      <SideMenuWrapper className={isVisible ? 'active' : ''}>
        <SideBarBack onClick={hideSideMenu}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" fill="none"><path fill="#fff" d="M.848 8.057c-.52.52-.52 1.365 0 1.886l7.543 7.542a1.333 1.333 0 1 0 1.885-1.885L5.01 10.332h17.657a1.333 1.333 0 1 0 0-2.667H5.01L10.276 2.4A1.333 1.333 0 0 0 8.391.515L.848 8.057Z"/></svg>
        </SideBarBack>

        <SideBar>
          <SideBarTop>
            

            <SideBarPointExt>
              <SideBarLink href={'https://theporndude.com/?ref=fupp'} target={'_blank'}>
                ThePornDude
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://thepornlinks.com/?ref=fupp'} target={'_blank'}>
                ThePornLinks
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://thepornmap.com/list/tiktok-porn-sites/'} target={'_blank'}>
                TikTok porn sites
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://porntourist.com/?ref=fupp'} target={'_blank'}>
                PornTourist
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://faperation.com/?ref=fupp'} target={'_blank'}>
                Faperation
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'http://bestpremiumpornsite.com/'} target={'_blank'}>
                Best Porn Sites
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://indienudes.com/'} target={'_blank'}>
                Indienudes
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://dirtypornworld.com/'} target={'_blank'} title={'Best Porn Sites List - Dirty Porn World'}>
                Dirty Porn World
              </SideBarLink>
            </SideBarPointExt>
             <SideBarPointExt>
              <SideBarLink href={'http://steezylist.com/'} target={'_blank'}>
                STEEZY PORN
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://ThePornBin.com'} target={'_blank'}>
                The Porn Bin
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://toplist18.com/'} target={'_blank'}>
                TopList18
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://www.thepornblender.com/'} target={'_blank'}>
                ThePornBlender
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://area51.to/'} target={'_blank'}>
                AREA51
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://doseofporn.com/'} target={'_blank'}>
                Dose of Porn
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://exxxtra.net/'} target={'_blank'}>
                Top Porn Sites List
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://fivestarpornsites.com/'} target={'_blank'}>
                Best Porn Sites
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://freyalist.com/'} target={'_blank'}>
                Freya List Porn
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://www.youpornlist.com/'} target={'_blank'}>
                YouPornList
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://lizardporn.com/'} target={'_blank'}>
                Lizard Porn
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://abellalist.com/'} target={'_blank'}>
                Abella List Porn
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://jennylist.xyz/'} target={'_blank'}>
                Jenny List Porn
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://zehnporn.com/'} target={'_blank'}>
                Zehn Porn
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://sharkyporn.com/'} target={'_blank'}>
                Sharky Porn
              </SideBarLink>
            </SideBarPointExt>
            <SideBarPointExt>
              <SideBarLink href={'https://moozporn.com/'} target={'_blank'}>
               Mooz Porn
              </SideBarLink>
            </SideBarPointExt>
          </SideBarTop>

          <SideBarBottom>
            <SideBarPointGray>
              <LinkExt to={'contacts'}>Contact us</LinkExt>
            </SideBarPointGray>
            <SideBarPointGray>
              <LinkExt to={'compliance'}>18 U.S.C. 2257</LinkExt>
            </SideBarPointGray>
            <SideBarPointGray>
              <LinkExt to={'dmca'}>DMCA</LinkExt>
            </SideBarPointGray>
            <SideBarPointGray>
              <LinkExt to={'cookies-privacy-policy'}>Cookies privacy policy</LinkExt>
            </SideBarPointGray>
            <SideBarPointGray>
              <LinkExt to={'terms-of-service'}>Terms of service</LinkExt>
            </SideBarPointGray>
          </SideBarBottom>
        </SideBar>
      </SideMenuWrapper>
    </>
  );
};

export default SideMenu;
