import styled from '@emotion/styled';
import { Link } from '@remix-run/react';

export const BottomNavigation = styled.nav`
  position: fixed;
  right: 0;
  bottom: env(safe-area-inset-bottom);
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  height: 56px;
  background-color: #000;
  z-index: 3;

  @media (display-mode: fullscreen) {
    height: 40px;
    align-items: flex-end;
  } 
`;

export const BottomNavLink = styled.a`
  flex: 0 0 25%;
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;

  path {
    fill: #FFFFFF;
    opacity: 0.6;
  }

  &.activeStyle {
    path {
      fill: #FFFFFF;
    }
  }
`;

export const LinkExtended = styled(Link)`
  flex: 0 0 25%;
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;

  path {
    fill: #FFFFFF;
  }

  &.active {
    path {
      fill: #EB1717;
    }
  }
`;

export const BottomNavIcon = styled.div`
  width: fit-content;
  margin: auto;
`;
