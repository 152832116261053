import type { LinksFunction, V2_MetaFunction, LoaderArgs } from '@remix-run/cloudflare';
import { json } from '@remix-run/cloudflare';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { Global } from '@emotion/react';
import { ErrorBoundary } from '@sentry/remix';
import { cssBundleHref } from "@remix-run/css-bundle";
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import "normalize.css?__remix_sideEffect__";
import "react-spring-bottom-sheet/dist/style.css?__remix_sideEffect__";
import "swiper/swiper.css?__remix_sideEffect__";

import { UnknownError } from './shared/ui/unknown-error';
import { TopNav } from './features/top-nav';
import { SideMenu, sideMenuModel } from './features/side-menu';
import { BottomNav } from './features/bottom-nav';
import { ComingSoonPopup, comingSoonPopupModel } from './features/coming-soon-popup';


import { GlobalStyle, Wrapper, Banner1, Banner2 } from './style';
import { If, Then } from "react-if";
import { AdultPopUp, adultPopupModel } from "./features/adult-popup";
import { useEffect, useState } from "react";
import { useUnit } from "effector-react";

export const meta: V2_MetaFunction = (args) => [
{ name: 'charset', content: 'utf-8' },
{ name: 'viewport', content: 'viewport-fit=cover, width=device-width' },
{ name: 'description', content: 'FUPP is the Free Porn site to watch TikTok Porn Videos. We optimize NSFW and XXX Porn Videos for viewing on your mobile.' },
{ name: 'keywords', content: 'tiktok, porn, sex, hot, real, nsfw, reddit, amateur, mobile, tikthots' },
{ name: 'rating', content: 'adult' },
{ name: 'rating', content: 'RTA-5042-1996-1400-1577-RTA' },
{ name: 'theme-color', content: '#000000' },
{ name: 'referrer', content: 'unsafe-url' },
{ name: 'mobile-web-app-capable', content: 'yes' },
{ name: 'apple-mobile-web-app-capable', content: 'yes' },
{ name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent' },
{
  property: 'og:title',
  content: 'FUPP: Hottest TikTok Porn and TikTok Nudes HD Sex Videos'
},
{
  property: 'og:description',
  content:
  'FUPP is the Free Porn site to watch TikTok Porn Videos. We optimize NSFW and XXX Porn Videos for viewing on your mobile.'
},
{
  property: 'og:type',
  content: 'website'
},
{
  property: 'og:site_name',
  content: 'FUPP'
},
{
  property: 'og:name',
  content: 'FUPP'
},
{
  property: 'og:url',
  content: 'https://fupp.app/'
},
{
  property: 'og:image',
  content: '/assets/icon-180.png'
},
{
  property: 'og:image:width',
  content: '180'
},
{
  property: 'og:image:height',
  content: '180'
}];


export const links: LinksFunction = () => [

{ rel: 'manifest', href: '/manifest.webmanifest' },
{ rel: 'icon', type: 'image/png', href: '/assets/icon-16.png', sizes: '16x16' },
{ rel: 'icon', type: 'image/png', href: '/assets/icon-32.png', sizes: '32x32' },
{ rel: 'icon', type: 'image/png', href: '/assets/icon-96.png', sizes: '96x96' },
{ rel: 'icon', type: 'image/png', href: '/assets/icon-120.png', sizes: '120x120' },
{ rel: 'icon', type: 'image/png', href: '/assets/icon-180.png', sizes: '180x180' },
{ rel: 'icon', type: 'image/png', href: '/assets/icon-192.png', sizes: '192x192' },
{ rel: 'icon', type: 'image/png', href: '/assets/icon-512.png', sizes: '512x512' },
{ rel: 'apple-touch-icon', type: 'image/png', href: '/assets/icon-512.png', sizes: '512x512' },
{ rel: 'dns-prefetch', href: 'https://fonts.googleapis.com' },
{ rel: 'preconnect', href: 'https://fonts.gstatic.com/' },

{ rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap&subset=latin' },

...(cssBundleHref ?
[{ rel: "stylesheet", href: cssBundleHref }] :
[]),

{ rel: 'manifest1', href: '/site1.webmanifest' }];


export async function loader({ context }: LoaderArgs) {
  return json({
    ENV: {
      SENTRY_RELEASE: context.CF_PAGES_COMMIT_SHA
    }
  });
}

export default function App() {
  const data = useLoaderData<typeof loader>();

  const [cookieState, setCookieState] = useState(true);
  const isAdultVisible = useUnit(adultPopupModel.$isAdultVisible);

  useEffect(() => {
    setCookieState(!!isAdultVisible);
  }, [isAdultVisible]);

  return (
    <html lang='en'>
    <head>
      <Meta />
      <Links />
    </head>
    <body>
    <Global styles={GlobalStyle} />
    <GTMProvider state={{ id: 'GTM-NP6W94B' }}>
      
      <Wrapper>
        <TopNav showSideMenu={sideMenuModel.showSideBar} />
        <ErrorBoundary fallback={<UnknownError />}>
          <Outlet />
        </ErrorBoundary>

        <script
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${JSON.stringify(
                  data.ENV
                )}; window.SENTRY_RELEASE=${JSON.stringify(data.ENV.SENTRY_RELEASE)};`
              }} />


        <BottomNav popup={<ComingSoonPopup />} showPopup={() => comingSoonPopupModel.showPopup()} />
        <SideMenu hideSideMenu={sideMenuModel.hideSideBar} />

        <If condition={!cookieState}>
          <Then>
            <AdultPopUp hide={adultPopupModel.hideAdult}></AdultPopUp>
          </Then>
        </If>
      </Wrapper>
      
    </GTMProvider>

    <ScrollRestoration />
    <Scripts />
    <LiveReload />
      
    </body>
    </html>);

}