import { createDomain } from 'effector';

import { attachLogger } from 'src/shared/lib/log';

const domain = createDomain('coming-soon-popup');

attachLogger(domain);

export const showPopup = domain.createEvent('showPopup');
export const hidePopup = domain.createEvent('hidePopup');

export const $isPopupVisible = domain.createStore(false, { name: 'isPopupVisible' });

$isPopupVisible.on(showPopup, () => true);
$isPopupVisible.on(hidePopup, () => false);
