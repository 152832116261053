import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const GlobalStyle = css`
  html, body {
    background: #000;
    color: #fff;
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
    min-height: calc(100% + env(safe-area-inset-top));
    padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    
    font-family: "Roboto", serif;
  }

`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: auto;
  max-width: 700px;
  position: relative;
  overflow: hidden;
`;

export const Banner1 = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 2rem;

  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const Banner2 = styled.div`
  display: block;
  position: fixed;
  right: 0;
  top: 2rem;

  @media screen and (max-width: 450px) {
    display: none;
  }
`;
