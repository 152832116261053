import { Button, TopNavigation } from './style';

interface IProps {
  showSideMenu: () => void;
}

export const TopNav = ({ showSideMenu }: IProps) => {
  return (
    <TopNavigation>
      <Button onClick={showSideMenu}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"><path fill="#fff" d="M26.667 24a1.333 1.333 0 0 1 .155 2.658l-.155.009H5.333a1.333 1.333 0 0 1-.155-2.658L5.333 24h21.334Zm0-9.333a1.333 1.333 0 0 1 0 2.666H5.333a1.333 1.333 0 1 1 0-2.666h21.334Zm0-9.334a1.333 1.333 0 1 1 0 2.667H5.333a1.333 1.333 0 0 1 0-2.667h21.334Z" /></svg>
      </Button>
      <svg width="91" height="32" viewBox="0 0 91 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1854_1307)">
          <path d="M7.86398 14.136H17.72V19.832H7.86398V14.136ZM8.37598 27H0.823975V4.59998H19.032V10.296H8.37598V27ZM32.1378 27.512C28.7031 27.512 26.0258 26.584 24.1058 24.728C22.1858 22.872 21.2258 20.2693 21.2258 16.92V4.59998H28.7778V16.696C28.7778 18.4027 29.0871 19.6187 29.7058 20.344C30.3244 21.048 31.1564 21.4 32.2018 21.4C33.2684 21.4 34.1004 21.048 34.6978 20.344C35.3164 19.6187 35.6258 18.4027 35.6258 16.696V4.59998H43.0498V16.92C43.0498 20.2693 42.0898 22.872 40.1698 24.728C38.2498 26.584 35.5724 27.512 32.1378 27.512ZM46.5115 27V4.59998H57.2955C59.3862 4.59998 61.1888 4.94131 62.7035 5.62398C64.2395 6.30665 65.4235 7.28798 66.2555 8.56798C67.0875 9.82665 67.5035 11.32 67.5035 13.048C67.5035 14.776 67.0875 16.2693 66.2555 17.528C65.4235 18.7867 64.2395 19.768 62.7035 20.472C61.1888 21.1547 59.3862 21.496 57.2955 21.496H50.7035L54.0635 18.264V27H46.5115ZM54.0635 19.096L50.7035 15.672H56.8155C57.8608 15.672 58.6288 15.4373 59.1195 14.968C59.6315 14.4987 59.8875 13.8587 59.8875 13.048C59.8875 12.2373 59.6315 11.5973 59.1195 11.128C58.6288 10.6586 57.8608 10.424 56.8155 10.424H50.7035L54.0635 6.99998V19.096ZM69.9803 27V4.59998H80.7643C82.8549 4.59998 84.6576 4.94131 86.1723 5.62398C87.7083 6.30665 88.8923 7.28798 89.7243 8.56798C90.5563 9.82665 90.9723 11.32 90.9723 13.048C90.9723 14.776 90.5563 16.2693 89.7243 17.528C88.8923 18.7867 87.7083 19.768 86.1723 20.472C84.6576 21.1547 82.8549 21.496 80.7643 21.496H74.1723L77.5323 18.264V27H69.9803ZM77.5323 19.096L74.1723 15.672H80.2843C81.3296 15.672 82.0976 15.4373 82.5883 14.968C83.1003 14.4987 83.3563 13.8587 83.3563 13.048C83.3563 12.2373 83.1003 11.5973 82.5883 11.128C82.0976 10.6586 81.3296 10.424 80.2843 10.424H74.1723L77.5323 6.99998V19.096Z" fill="white"/>
        </g>
      </svg>
      <a href="https://www.kupid.ai/?src_ref=e81032d92" target="_blank">
        <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.7594 32.1384V29.9031V26.7737L19.727 27.4443C19.727 27.4443 20.3976 29.5119 21.8506 29.7355C22.4653 29.8472 22.9123 29.5119 23.4711 29.009L23.08 31.9708C23.0241 32.4737 23.3594 32.9208 23.9182 32.8649H25.6506L30.68 32.809C31.2947 32.809 31.7976 32.4178 31.9653 31.9149C32.1329 31.3002 31.6859 30.7972 30.9594 30.7413L26.0976 30.4061L26.9359 25.656C26.9359 25.6002 26.9917 25.4884 26.9917 25.4325C27.1594 24.0354 26.0417 22.5825 24.477 22.7501C23.2476 22.8619 21.627 23.3649 19.5594 22.9178C19.5594 22.9178 19.5594 22.9178 19.5594 22.9737C19.3917 23.4207 19.0564 23.756 18.6094 23.9796C18.3858 24.0913 18.1623 24.0913 17.9388 24.0913C17.38 24.0913 16.8211 23.8119 16.4858 23.3649L13.9711 20.5707C13.9152 20.5707 13.8035 20.5707 13.7476 20.5707C12.1829 20.459 10.7299 21.9678 10.8417 23.5325C10.8976 24.0354 11.2329 25.209 11.4005 25.8237L12.127 30.1266L11.0093 30.1825V31.9149C11.0093 32.3619 10.8417 32.6972 10.6182 32.9766L14.8652 33.0884C15.3123 33.1443 15.7594 32.6972 15.7594 32.1384Z" fill="white"/>
<path d="M16.1823 4.67557C16.4817 2.69193 15.1162 0.841245 13.1326 0.541935C11.149 0.242625 9.29829 1.60804 8.99898 3.59167C8.69967 5.5753 10.0651 7.42599 12.0487 7.7253C14.0323 8.02461 15.883 6.6592 16.1823 4.67557Z" fill="white"/>
<path d="M9.50003 32.9766C10.0589 32.9766 10.5059 32.4737 10.5059 31.9149V28.6737L10.0589 22.7501L11.9589 16.4913L12.4618 18.0001L16.8765 22.9737C17.1001 23.309 17.4912 23.5325 17.9383 23.5325C18.1059 23.5325 18.2736 23.4766 18.4412 23.4207C19.1118 23.1413 19.3912 22.4148 19.0559 21.856L18.3854 20.9619C19.2236 20.7384 20.0618 20.9619 20.6207 21.0737C21.2354 21.2413 21.906 21.0737 22.4089 20.5707C23.1354 19.8442 23.1913 18.6148 22.4089 17.8884C22.2971 17.7766 22.1854 17.6648 22.0736 17.6089C21.906 17.4972 21.7383 17.4413 21.4589 17.3854C21.403 17.3854 21.3471 17.3854 21.2354 17.3295C19.3354 17.0501 18.1618 18.4472 17.4912 19.8442L15.2559 16.8266L13.4677 10.1207C13.4118 9.95304 13.3559 9.84127 13.2442 9.72951C12.9089 9.00303 12.1824 8.38832 11.2883 8.22068C11.0647 8.16479 10.8412 8.16479 10.5618 8.16479C9.22062 8.16479 8.04708 9.00303 7.71179 10.2324L4.58237 20.0678C4.35884 21.0178 4.63825 21.9678 5.30884 22.6384L6.20296 28.6737H2.34707C1.06177 28.6737 0 29.5678 0 30.7413C0 31.859 1.00589 32.7531 2.2353 32.809L9.50003 32.9766Z" fill="white"/>
<path d="M30.6355 19.2588C30.9348 17.2752 29.5694 15.4245 27.5857 15.1252C25.6021 14.8259 23.7514 16.1913 23.4521 18.1749C23.1528 20.1586 24.5182 22.0092 26.5018 22.3086C28.4855 22.6079 30.3362 21.2425 30.6355 19.2588Z" fill="white"/>
</svg>
      </a>
    </TopNavigation>
  );
};
