import { useUnit } from 'effector-react';

import { Bold } from 'src/shared/style/style';

import { $isPopupVisible, hidePopup } from './model';
import { BottomSheetExtended, PopupBtn, PopupIcon, PopupText, PopupWrapper } from './style';

export const ComingSoonPopup = () => {
  const isVisible = useUnit($isPopupVisible);

  return (
    <BottomSheetExtended open={isVisible} blocking={true} onDismiss={hidePopup}
                         defaultSnap={({ snapPoints, lastSnap }) =>
                           lastSnap ?? Math.min(...snapPoints)
                         }
                         snapPoints={({ maxHeight }) => [
                           maxHeight - maxHeight / 2,
                           maxHeight * 0.6,
                         ]}
                         expandOnContentDrag={true}>
      <PopupWrapper>
        <PopupIcon src='https://fonts.gstatic.com/s/e/notoemoji/latest/1f4a1/512.gif' alt='💡' width='64' height='64' />
        <PopupText>
          <Bold>In progress</Bold>
          <p>If you have any feedback, message us via Telegram!</p>
        </PopupText>
        <PopupBtn href={'https://t.me/fupp_official'} target={'_blank'} aria-label='go to telegram group'>
          Go to Telegram
        </PopupBtn>
      </PopupWrapper>
    </BottomSheetExtended>
  );
};
