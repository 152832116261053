import styled from '@emotion/styled';
import { BottomSheet } from 'react-spring-bottom-sheet';

export const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  box-sizing: border-box;
  width: calc(100% - 30px);
  max-width: 400px;
  height: auto;
  margin: 15px;
`;

export const PopupIcon = styled.img`
  width: 64px;
  height: 64px;
  margin: auto;
`;

export const PopupText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  font-size: 16px;
  line-height: 20px;
  color: black;
  text-align: center;
  margin: auto;

  p {
    margin: 0;
  }
`;

export const PopupBtn = styled.a`
  cursor: pointer;
  text-decoration: unset;
  color: white;

  padding: 20px 50px;
  background-color: #EB1717;
  border-radius: 13px;
  margin: auto;

  &:hover {
    opacity: .8;
  }
`;

export const BottomSheetExtended = styled(BottomSheet)`
  [data-rsbs-overlay] {
    width: calc(100% - 30px);
    height: fit-content;
    margin: 15px auto;
    border-radius: 13px;

    @media screen and (min-width: 450px) {
      width: 430px;
    }
  }
  [data-rsbs-backdrop] {
    opacity: 0;
  }
`;
