import { createDomain } from 'effector';

import { attachLogger } from 'src/shared/lib/log';
import { createCookiesStore } from "../../shared/lib/cookies";
import { addDays } from "date-fns";

const domain = createDomain('adult-popup');

attachLogger(domain);

export const showAdult = domain.createEvent();
export const hideAdult = domain.createEvent();

export const $isAdultVisible = createCookiesStore('isAdultVisible', addDays(new Date(), 10));

$isAdultVisible.on(showAdult, () => null);
$isAdultVisible.on(hideAdult, () => addDays(new Date(), 10));
